import ng from 'angular';
import * as rx from '@proftit/rxjs';
import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import template from './brand-account-status-automation-item.component.html';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import { BrandsService } from '~/source/management/brand/services/brands';
import { generateBlockuiId } from '~/source/common/utilities/generate-blockui-id';
import { generateGrowlId } from '~/source/common/utilities/generate-growl-id';
import * as _ from '@proftit/lodash';
import { AccountStatusAutomationTypesService } from '~/source/common/services/account-status-automation-types.service';
import { AccountStatusAutomationTypeCode } from '@proftit/crm.api.models.enums';
import { FormArray, FormControl, FormGroup } from '@proftit/ng1.reactive-forms';
import { Editable } from '~/source/common/utilities/editable';
import { getAccountStatusAutomationMapFormGroups } from '~/source/management/automation/shared/get-account-status-automation-map-form-groups';
import { AccountStatusAutomationRule } from '@proftit/crm.api.models.entities';

const styles = require('./brand-account-status-automation-item.component.scss');

export class BrandAccountStatusAutomationItemController {
  styles = styles;
  lifecycles = observeComponentLifecycles(this);
  blockUiId = generateBlockuiId();
  growlId = generateGrowlId();
  brandId$ = observeShareCompChange<number>(
    this.lifecycles.onChanges$,
    'brandId',
  );
  brandPlatformType: string;

  accountStatusAutomationTypesServiceInst: AccountStatusAutomationTypesService;

  leftSideEditManager = new Editable();
  rightSideEditManager = new Editable();

  accountStatusAutomationTypes$ = this.streamAccountStatusAutomationTypes();
  leftSideAccountStatusAutomationTypes$ = this.streamLeftSideAccountStatusAutomationTypes();
  rightSideAccountStatusAutomationTypes$ = this.streamRightSideAccountStatusAutomationTypes();

  accountStatusAutomationRules$ = this.streamAccountStatusAutomationRules();
  leftSideRules$ = this.streamLeftSideRules();
  rightSideRules$ = this.streamRightSideRules();

  rightSideAccountStatusAutomationRuleFormGroup$ = this.streamRightSideAccountStatusAutomationRuleFormGroup();
  leftSideAccountStatusAutomationRuleFormGroup$ = this.streamLeftSideAccountStatusAutomationRuleFormGroup();

  leftSideRuleFormGroupValue$ = this.streamLeftSideRuleFormGroupValue();
  rightSideRuleFormGroupValue$ = this.streamRightSideRuleFormGroupValue();

  /*@ngInject */
  constructor(
    readonly brandsService: () => BrandsService,
    readonly accountStatusAutomationTypesService: () => AccountStatusAutomationTypesService,
    readonly PermPermissionStore: ng.permission.PermissionStore,
  ) {
    this.accountStatusAutomationTypesServiceInst = this.accountStatusAutomationTypesService();

    useStreams(
      [
        this.leftSideEditManager.initiator$,
        this.rightSideEditManager.initiator$,
        this.accountStatusAutomationTypes$,
        this.accountStatusAutomationRules$,
        this.leftSideRules$,
        this.rightSideRules$,
        this.rightSideAccountStatusAutomationRuleFormGroup$,
        this.leftSideAccountStatusAutomationRuleFormGroup$,
        this.leftSideRuleFormGroupValue$,
        this.rightSideRuleFormGroupValue$,
        this.streamSaveLeftSide(),
        this.streamSaveRightSide(),
      ],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}

  streamLeftSideRuleFormGroupValue() {
    return rx.pipe(
      () => this.leftSideAccountStatusAutomationRuleFormGroup$,
      rx.switchMap((formGroup) => formGroup.value$),
      shareReplayRefOne(),
    )(null);
  }

  streamRightSideRuleFormGroupValue() {
    return rx.pipe(
      () => this.rightSideAccountStatusAutomationRuleFormGroup$,
      rx.switchMap((formGroup) => formGroup.value$),
      shareReplayRefOne(),
    )(null);
  }

  streamAccountStatusAutomationTypes() {
    return rx.pipe(
      () => this.lifecycles.onInitShared$.pipe(rx.filter((x) => x)),
      rx.switchMap(() => {
        return rx.obs
          .from(
            this.accountStatusAutomationTypesServiceInst
              .embed('platformTypes')
              .filter('platformTypes.code', this.brandPlatformType)
              .getListWithQuery()
              .then((res) => res.plain()),
          )
          .pipe(rx.catchError(() => rx.obs.NEVER));
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamLeftSideAccountStatusAutomationTypes() {
    return rx.pipe(
      () => this.accountStatusAutomationTypes$,
      rx.map((types) =>
        types.filter(
          (type) => type.code !== AccountStatusAutomationTypeCode.Ftd,
        ),
      ),
      shareReplayRefOne(),
    )(null);
  }

  streamRightSideAccountStatusAutomationTypes() {
    return rx.pipe(
      () => this.accountStatusAutomationTypes$,
      rx.map((types) =>
        types.filter(
          (type) => type.code === AccountStatusAutomationTypeCode.Ftd,
        ),
      ),
      shareReplayRefOne(),
    )(null);
  }

  filterAutomationTypes(allowedTypeCodes) {
    return rx.pipe(
      () =>
        rx.obs.combineLatest(
          this.accountStatusAutomationTypes$,
          this.accountStatusAutomationRules$,
        ),
      rx.map(([types, rules]) => {
        const allowedTypeIds = types
          .filter((type) => allowedTypeCodes.includes(type.code))
          .map((type) => type.id);
        return {
          types,
          rules,
          allowedTypeIds,
        };
      }),
    )(null);
  }

  getAllowedTypeIds(types, allowedTypeCodes) {
    return types
      .filter((type) => allowedTypeCodes.includes(type.code))
      .map((type) => type.id);
  }

  streamLeftSideAccountStatusAutomationRuleFormGroup() {
    const lastLeftSideRule$ = new rx.BehaviorSubject(null);
    return rx.pipe(
      () =>
        rx.obs.merge(
          this.streamLeftSideRuleFromModel(),
          this.streamLeftSideRuleFromCancel(lastLeftSideRule$),
        ),
      rx.tap((rule) => {
        lastLeftSideRule$.next(rule);
      }),
      rx.map((rule) => this.getRuleFormGroup(rule)),
      shareReplayRefOne(),
    )(null);
  }

  streamLeftSideRuleFromModel() {
    return rx.pipe(
      () =>
        rx.obs.combineLatest(
          this.accountStatusAutomationTypes$,
          this.leftSideRules$,
        ),
      rx.map(([types, leftSideRules]) => {
        const allowedTypeIds = this.getAllowedTypeIds(types, [
          AccountStatusAutomationTypeCode.ComplianceChange,
          AccountStatusAutomationTypeCode.Registration,
        ]);
        return {
          types,
          allowedTypeIds,
          rules: leftSideRules,
        };
      }),
      rx.map(({ types, rules, allowedTypeIds }) => {
        return this.getRule(
          types,
          rules,
          allowedTypeIds,
          AccountStatusAutomationTypeCode.ComplianceChange,
        );
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamLeftSideRuleFromCancel(
    leftSideRule$: rx.Observable<AccountStatusAutomationRule>,
  ) {
    return rx.pipe(
      () => this.leftSideEditManager.cancelEditAction,
      rx.withLatestFrom(leftSideRule$),
      rx.map(([a, rule]) => rule),
      shareReplayRefOne(),
    )(null);
  }

  streamRightSideAccountStatusAutomationRuleFormGroup() {
    const rightLeftSideRule$ = new rx.BehaviorSubject(null);
    return rx.pipe(
      () =>
        rx.obs.merge(
          this.streamRightSideRuleFromModel(),
          this.streamRightSideRuleFromCancel(rightLeftSideRule$),
        ),
      rx.tap((rule) => {
        rightLeftSideRule$.next(rule);
      }),
      rx.map((rule) => this.getRuleFormGroup(rule)),
      shareReplayRefOne(),
    )(null);
  }

  streamRightSideRuleFromModel() {
    return rx.pipe(
      () =>
        rx.obs.combineLatest(
          this.accountStatusAutomationTypes$,
          this.rightSideRules$,
        ),
      rx.map(([types, rightSideRules]) => {
        const allowedTypeIds = this.getAllowedTypeIds(types, [
          AccountStatusAutomationTypeCode.Ftd,
        ]);
        return {
          types,
          allowedTypeIds,
          rules: rightSideRules,
        };
      }),
      rx.map(({ types, rules, allowedTypeIds }) => {
        return this.getRule(
          types,
          rules,
          allowedTypeIds,
          AccountStatusAutomationTypeCode.Ftd,
        );
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamRightSideRuleFromCancel(
    rightSideRule$: rx.Observable<AccountStatusAutomationRule>,
  ) {
    return rx.pipe(
      () => this.rightSideEditManager.cancelEditAction,
      rx.withLatestFrom(rightSideRule$),
      rx.map(([a, rule]) => rule),
      shareReplayRefOne(),
    )(null);
  }

  getRule(
    types,
    rules,
    allowedTypeIds,
    defaultRuleTypeCode: AccountStatusAutomationTypeCode,
  ) {
    const rule = rules.find((rule) => allowedTypeIds.includes(rule.type.id));
    const isRuleNotNil = !_.isNil(rule);
    const defaultRuleType = types.find(
      (type) => type.code === defaultRuleTypeCode,
    );
    const ruleType = isRuleNotNil ? rule.type : defaultRuleType;
    return {
      _isCreate: !isRuleNotNil,
      id: isRuleNotNil ? rule.id : null,
      delay: isRuleNotNil ? rule.delay : 0,
      isActive: isRuleNotNil ? rule.isActive : false,
      type: {
        id: ruleType.id,
        code: ruleType.code,
        name: ruleType.name,
      },
      accountStatusAutomationMaps: isRuleNotNil
        ? rule.accountStatusAutomationMaps
        : [],
    };
  }

  getRuleFormGroup(rule) {
    const formGroup = new FormGroup({
      _isCreate: new FormControl<boolean>(rule._isCreate),
      id: new FormControl<number>(rule.id),
      delay: new FormControl<number>(rule.delay),
      isActive: new FormControl<boolean>(rule.isActive),
      type: new FormControl({
        id: rule.type.id,
        code: rule.type.code,
        name: rule.type.name,
      }),
      accountStatusAutomationMaps: new FormArray(
        getAccountStatusAutomationMapFormGroups(
          rule.accountStatusAutomationMaps,
        ),
      ),
    } as any);
    return formGroup;
  }

  streamAccountStatusAutomationRules(): rx.Observable<
    AccountStatusAutomationRule[]
  > {
    return rx.pipe(
      () =>
        rx.obs.merge(
          this.brandId$,
          this.leftSideEditManager.saveIsDoneAction,
          this.rightSideEditManager.saveIsDoneAction,
        ),
      rx.withLatestFrom(this.brandId$),
      rx.switchMap(([a, brandId]) => {
        return rx.obs
          .from(this.brandsService().getAccountStatusAutomationRules(brandId))
          .pipe(rx.catchError(() => rx.obs.NEVER));
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamLeftSideRules() {
    const lastRules$ = new rx.BehaviorSubject<AccountStatusAutomationRule[]>(
      null,
    );
    return rx.pipe(
      () => this.accountStatusAutomationRules$,
      rx.map((allRules) =>
        allRules.filter((rule) =>
          [
            AccountStatusAutomationTypeCode.Registration.valueOf(),
            AccountStatusAutomationTypeCode.ComplianceChange.valueOf(),
          ].includes(rule.type.code),
        ),
      ),
      rx.withLatestFrom(lastRules$),
      rx.filter(([newRules, lastRules]) => !_.isEqual(newRules, lastRules)),
      rx.map(([newRules, lastRules]) => newRules),
      rx.tap((newRules: AccountStatusAutomationRule[]) => {
        lastRules$.next(newRules);
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamRightSideRules() {
    const lastRules$ = new rx.BehaviorSubject<AccountStatusAutomationRule[]>(
      null,
    );
    return rx.pipe(
      () => this.accountStatusAutomationRules$,
      rx.map((allRules) =>
        allRules.filter((rule) =>
          [AccountStatusAutomationTypeCode.Ftd.valueOf()].includes(
            rule.type.code,
          ),
        ),
      ),
      rx.withLatestFrom(lastRules$),
      rx.filter(([newRules, lastRules]) => !_.isEqual(newRules, lastRules)),
      rx.map(([newRules, lastRules]) => newRules),
      rx.tap((newRules: AccountStatusAutomationRule[]) => {
        lastRules$.next(newRules);
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamSaveLeftSide() {
    return rx.pipe(
      () => this.leftSideEditManager.saveAction,
      rx.withLatestFrom(this.leftSideRuleFormGroupValue$, this.brandId$),
      rx.switchMap(([a, formValue, brandId]) => {
        return rx.obs
          .from(this.saveRule(brandId, formValue))
          .pipe(rx.catchError(() => rx.obs.NEVER));
      }),
      rx.tap(() => {
        this.leftSideEditManager.saveIsDoneAction.next();
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamSaveRightSide() {
    return rx.pipe(
      () => this.rightSideEditManager.saveAction,
      rx.withLatestFrom(this.rightSideRuleFormGroupValue$, this.brandId$),
      rx.switchMap(([a, formValue, brandId]) => {
        return rx.obs
          .from(this.saveRule(brandId, formValue))
          .pipe(rx.catchError(() => rx.obs.NEVER));
      }),
      rx.tap(() => {
        this.rightSideEditManager.saveIsDoneAction.next();
      }),
      shareReplayRefOne(),
    )(null);
  }

  normalizeForm(formValue) {
    const { _isCreate, ...rest } = formValue;
    const accountStatusAutomationMaps = formValue.accountStatusAutomationMaps.map(
      (map) => {
        return {
          customerVerifiedStatusId: map.customerVerifiedStatus.id,
          accountStatusId: map.accountStatus.id,
        };
      },
    );
    return {
      ...rest,
      accountStatusAutomationMaps,
      typeId: rest.type.id,
    };
  }

  saveRule(brandId: number, rule) {
    const dataToSend = this.normalizeForm(rule);
    if (rule._isCreate) {
      return this.postRule(brandId, dataToSend);
    }
    return this.updateRule(brandId, rule.id, dataToSend);
  }

  postRule(brandId: number, data) {
    return this.brandsService()
      .setConfig({ blockUiRef: this.blockUiId, growlRef: this.growlId })
      .postAccountStatusAutomationRules(brandId, data);
  }

  updateRule(brandId: number, ruleId: number, data) {
    return this.brandsService()
      .setConfig({ blockUiRef: this.blockUiId, growlRef: this.growlId })
      .patchAccountStatusAutomationRule(brandId, ruleId, data);
  }
}

export const BrandAccountStatusAutomationItemComponent = {
  template,
  controller: BrandAccountStatusAutomationItemController,
  bindings: {
    brandId: '<',
    brandPlatformType: '<',
  },
};
