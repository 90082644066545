import * as rx from '@proftit/rxjs';
import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import template from './challenge-phase-container.component.html';
import CustomersService from '~/source/contact/common/services/customers';
import {
  Challenge,
  ChallengePhase,
  Customer,
  TradingAccount,
} from '@proftit/crm.api.models.entities';
import IElementRestNg from '~/source/common/models/ielement-rest-ng';
import * as _ from '@proftit/lodash';
import { IScope, ITimeoutService } from 'angular';
import { StateParams } from '@uirouter/core';

const styles = require('./challenge-phase-container.component.scss');

function parseActiveAccountId(accountIdStr) {
  return parseInt(accountIdStr, 10);
}

export class ChallengePhaseContainerController {
  styles = styles;
  lifecycles = observeComponentLifecycles(this);
  challenge$ = observeShareCompChange<Challenge>(
    this.lifecycles.onChanges$,
    'challenge',
  );
  challenge: Challenge;
  customer: Customer;
  accounts$ = this.streamAccounts();
  activeAccountId: number;
  activeAccountId$ = new rx.BehaviorSubject<number>(null);
  customersServiceInst = this.customersService();
  phaseNames: Record<number, string> = {};
  phases: ChallengePhase[];
  selectedPhase: ChallengePhase;

  /*@ngInject*/
  constructor(
    readonly $scope: IScope,
    readonly $stateParams: StateParams,
    readonly $timeout: ITimeoutService,
    readonly customersService: () => CustomersService,
    readonly platformTypesMap: any,
  ) {
    useStreams(
      [this.streamAccounts(), this.streamSetActiveAccountOnAccountsOnFirst()],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {
    this.$scope.$watch('$ctrl.activeAccountId', (newVal: number, oldVal) => {
      if (newVal !== oldVal) {
        this.activeAccountId$.next(newVal);
        this.selectedPhase = this.phases.find((phase) => {
          return phase.tradingAccountId === newVal;
        });
      }
    });
  }

  $onDestroy() {}

  $onChanges() {}

  streamAccounts() {
    return rx.pipe(
      () => this.challenge$,
      rx.startWith(null),
      rx.pairwise(),
      rx.filter(([prev, curr]) => prev?.id !== curr?.id),
      rx.map(() => [this.customer, this.challenge]),
      rx.filter(([customer, challenge]) => {
        return !_.isNil(customer) && !_.isNil(challenge);
      }),
      rx.switchMap(([customer, challenge]) => {
        if (this.challenge.phases.length === 0) {
          return rx.obs.EMPTY;
        }
        const phases = this.challenge.phases as ChallengePhase[];
        this.phases = phases;
        const tradingAccountIds = phases.reduce((acc, phase) => {
          if (phase.tradingAccountId) {
            this.phaseNames[phase.tradingAccountId] = phase.name;
            acc.push(phase.tradingAccountId);
          }
          return acc;
        }, []);
        if (tradingAccountIds.length > 0) {
          return rx.obs.from(
            this.fetchCustomerTradingAccounts(customer, tradingAccountIds),
          );
        }
        return rx.obs.EMPTY;
      }),
      rx.map((data) => {
        return data;
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamSetActiveAccountOnAccountsOnFirst() {
    return rx.pipe(
      () => this.accounts$,
      rx.map((accounts) => {
        if (accounts.length === 0) {
          return null;
        }

        if (_.isNil(this.$stateParams.accountId)) {
          return accounts[0].id;
        }

        const activeAccountId = parseActiveAccountId(
          this.$stateParams.accountId,
        );

        if (accounts.find((ac) => ac.id === activeAccountId)) {
          return activeAccountId;
        }

        return null;
      }),
      rx.tap((activeId) => (this.activeAccountId = activeId)),
      rx.map(() => rx.obs.EMPTY),
    )(null);
  }

  async fetchCustomerTradingAccounts(
    customer: Customer,
    tradingAccountIds: number[],
  ): Promise<TradingAccount[]> {
    const platformType = this.platformTypesMap[customer.brand.platformType.id];
    /**
     * Additional request expands for each of the trading account types
     * @type {{binary: [string], forex: [string]}}
     */
    const expandsByType = {
      binary: ['tradingAccountType'],
      forex: ['tradingAccountGroup'],
      prop: ['tradingAccountGroup'],
    };
    const platformExpands = expandsByType[platformType];

    return this.customersServiceInst
      .getAccountsResource(customer.id, platformType)
      .setConfig({
        blockUiRef: 'challengeAccounts',
        growlRef: 'challengeAccounts',
        errorsTranslationPath: 'contact.errors',
      })
      .filter('id', tradingAccountIds)
      .expand(['platform', 'currency', ...platformExpands])
      .getListWithQuery<IElementRestNg<TradingAccount>>()
      .then((accounts) => accounts.plain())
      .then((accounts) =>
        accounts.map((account) => ({
          ...account,
          statusCode: { code: account.statusCode },
        })),
      );
  }
}

export const ChallengePhaseContainerComponent = {
  template,
  controller: ChallengePhaseContainerController,
  bindings: {
    challenge: '<',
    customer: '<',
  },
};
