import addAccountPopupTemplate from '../trading-account/add-account-popup.html';
import template from './contact-page.html';
import BaseController from '../../../common/controllers/base';
import { IScope } from 'angular';
import * as _ from '@proftit/lodash';
import CustomersSocket from '~/source/contact/common/services/customers-socket';
import { StateService } from '@uirouter/core';
import PopupService from '~/source/common/components/modal/popup.service';
import TokensService from '~/source/auth/services/tokens';
import { Customer } from '@proftit/crm.api.models.entities';
import { SocketListener } from '~/source/common/services/socket';
import { CustomerNavigationService } from '~/source/common/services/customer-navigation.service';
import * as rx from '@proftit/rxjs';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import FilterService from '~/source/common/components/table-filters/services/filter.service';
import FilterCacheService from '~/source/common/components/table-filters/services/filter-cache';
import CustomersService from '~/source/contact/common/services/customers';
import { CustomerStoreProviderController } from '~/source/contact/common/components/customer-store-provider/customer-store-provider.component';
import { TimeInterval } from '@proftit/constants.time';
import moment from 'moment-timezone';
import { PlatformTypeCode } from '@proftit/crm.api.models.enums';

const styles = require('./contact-page.component.scss');

class ContactPageController extends BaseController {
  styles = styles;
  contactId: number;
  customer: Customer;
  channel: string;
  filterData: any;
  showFilterBar$ = new rx.BehaviorSubject<boolean>(false);
  navigationFiltersChanged$ = new rx.Subject<void>();
  addAccountOp$ = new rx.Subject<void>();
  lifecycles = observeComponentLifecycles(this);

  filterData$ = this.streamFilterData();
  prfCustomerStoreProvider: CustomerStoreProviderController;
  onUpdatedBinded: SocketListener;
  quickFilters = {
    newContacts: {},
    onlineContacts: {},
    realContacts: {
      state: {
        isActive: true,
      },
    },
  };
  isPropCustomer: boolean;

  /*@ngInject*/
  constructor(
    readonly $scope: IScope,
    readonly customersSocketService: CustomersSocket,
    readonly $state: StateService,
    readonly $stateParams: Record<string, string>,
    readonly popupService: PopupService,
    readonly tokensService: TokensService,
    readonly prfCustomerNavigationService: CustomerNavigationService,
    readonly filterService: FilterService,
    readonly filterCache: FilterCacheService,
    readonly customersService: () => CustomersService,
    readonly prfAppTag: any,
  ) {
    super();
    useStreams(
      [
        this.filterData$,
        this.streamGoToNextCustomer(),
        this.streamGoToPreviousCustomer(),
        this.streamGoToNewFilterCustomer(),
      ],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {
    useStreams(
      [
        this.getCustomerObs(),
        this.streamIsPropCustomer(),
        this.streamAddAccount(),
        this.filterData$.pipe(
          rx.filter((filterData) => !_.isNil(filterData)),
          rx.tap((filterData) => {
            this.prfCustomerNavigationService.shouldShowNavigation$.next(
              filterData.showCustomerNavigation,
            );
          }),
        ),
        this.lifecycles.onInitShared$.pipe(
          rx.filter((x) => x),
          rx.tap(() => {
            this.prfCustomerNavigationService.disabledPrevious$.next(
              parseInt(this.$state.params.currentOffset, 10) === 0,
            );

            this.prfCustomerNavigationService.disabledNext$.next(
              parseInt(this.$state.params.currentOffset, 10) + 1 ===
                parseInt(this.$state.params.total, 10),
            );
          }),
        ),
      ],
      this.lifecycles.onDestroy$,
    );
    this.prfCustomerStoreProvider.customerStoreService
      .load(parseInt(this.$stateParams.id, 10))
      .subscribe();

    this.contactId = this.$state.params.id;

    // This event occurs after clicking the "update" button and receiving a success response from server
    this.$scope.$on('contact:update:success', (e, data) => {
      this.$scope.$broadcast('contact:update', data);
    });

    this.onUpdatedBinded = this.customersSocketService.wrapListener((props) =>
      this.onCustomerUpdate(props),
    );
    this.channel = this.buildChannel();
    // listen for updates on customer
    this.listenTo();

    this.$scope.$on('table:filter:updated', () => {
      this.navigationFiltersChanged$.next();
    });
  }

  getCustomerObs() {
    return this.prfCustomerStoreProvider.customerStoreService.customer$;
  }

  streamIsPropCustomer() {
    return this.prfCustomerStoreProvider.customerStoreService.customer$.pipe(
      rx.filter((customer) => !_.isNil(customer)),
      rx.tap((customer) => {
        this.isPropCustomer =
          customer.brand.platformType.code === PlatformTypeCode.Prop;
      }),
    );
  }

  category() {
    return 'contacts';
  }

  cachekey() {
    return this.filterData.cachekey;
  }

  /**
   * Channel for account statistics updates
   *
   * @returns {string}
   */
  buildChannel() {
    return `user.${this.tokensService.getCachedUser().id}.${
      this.customersSocketService.channelRoot
    }.${this.contactId}`;
  }

  /**
   * Subscribe for account stats updates
   */
  listenTo() {
    this.customersSocketService.subscribe(this.channel, this.onUpdatedBinded);
  }

  /**
   * Unsubscribe from account updates
   */
  stopListening() {
    this.customersSocketService.unsubscribe(this.channel, this.onUpdatedBinded);
  }

  /**
   * Unsubscribe from updates on account
   */
  $onDestroy() {
    this.stopListening();
  }

  onCustomerUpdate(propertiesToUpdate) {
    this.prfCustomerStoreProvider.customerStoreService.updateCustomerFromSocket(
      propertiesToUpdate,
    );
  }

  /**
   * Called when on when user want to add account to customer
   */
  onAddAccount() {
    this.addAccountOp$.next();
  }

  streamAddAccount() {
    return rx.pipe(
      () => this.addAccountOp$,
      rx.withLatestFrom(
        this.prfCustomerStoreProvider.customerStoreService.customer$,
      ),
      rx.tap(([omg, customer]) => {
        this.popupService.open({
          controller: 'AddAccountPopupController',
          template: addAccountPopupTemplate,
          scope: this.$scope,
          data: {
            customerInfo: customer,
          },
        });
      }),
    )(null);
  }

  streamFilterData() {
    return rx.pipe(
      () => this.lifecycles.onInit$,
      rx.map(() => {
        const { navigationId, userId, guid } = this.$stateParams;
        const timestamp = parseInt(this.$stateParams.timestamp, 10);
        const key = `contacts.${userId}.${navigationId}`;
        const filter = this.filterCache.get(key);

        if (_.isNil(navigationId)) {
          return { showCustomerNavigation: false };
        }

        if (
          (_.isNil(filter) &&
            moment(timestamp).add(1, 'days').isBefore(moment())) ||
          this.prfAppTag.murmur !== guid ||
          parseInt(userId, 10) !== this.tokensService.getCachedUser().id
        ) {
          this.$state.go(
            'crm.contacts.view',
            { id: this.$stateParams.id },
            { inherit: false },
          );
          return {
            showCustomerNavigation: false,
          };
        }

        if (_.isNil(filter)) {
          this.filterCache.duplicateFilter('contacts', key, {
            maxAge: TimeInterval.Day,
          });
        }

        return {
          showCustomerNavigation: true,
          cachekey: key,
        };
      }),
      rx.tap((filterData) => (this.filterData = filterData)),
      shareReplayRefOne(),
    )(null);
  }

  streamGoToNextCustomer() {
    const nextOffset = parseInt(this.$state.params.currentOffset, 10) + 1;

    return rx.pipe(
      () => this.prfCustomerNavigationService.goToNextCustomerOp$,
      rx.switchMap(() => {
        return this.getCustomerWithFilters(nextOffset);
      }),
      rx.map(({ customer, total }) => {
        if (_.isNil(customer?.id) && total <= nextOffset) {
          this.prfCustomerNavigationService.disabledNext$.next(true);
        }
        return { customer, total };
      }),
      rx.filter(({ customer, total }) => !_.isNil(customer?.id)),
      rx.tap(({ customer, total }) => {
        this.goToCustomer(customer.id, nextOffset);
      }),
    )(null);
  }

  streamGoToPreviousCustomer() {
    const nextOfsset =
      Number.parseInt(this.$state.params.currentOffset, 10) - 1;

    return rx.pipe(
      () => this.prfCustomerNavigationService.goToPreviousCustomerOp$,
      rx.switchMap(() => {
        return this.getCustomerWithFilters(nextOfsset);
      }),
      rx.filter(({ customer, total }) => !_.isNil(customer?.id)),
      rx.tap(({ customer, total }) => {
        this.goToCustomer(customer.id, nextOfsset);
      }),
    )(null);
  }

  streamGoToNewFilterCustomer() {
    return rx.pipe(
      () => this.navigationFiltersChanged$,
      // the update event from the filter bar emit also when comp initiate.
      // so we dont need the filter to reload the page at the first event. so we skip the first one
      rx.skip(1),
      rx.debounceTime(300),
      rx.switchMap(() => {
        return this.getCustomerWithFilters(0);
      }),
      rx.tap(({ customer, total }) => {
        if (total === 0 || _.isNil(customer?.id)) {
          // if the filter is empty, go back to the contact list page, with the choosen filter
          const filterModels = this.filterCache.get(this.filterData.cachekey);
          this.filterCache.put('contacts', filterModels);
          this.$state.go('crm.contacts.list', undefined, { inherit: false });
          return;
        }
        this.goToCustomer(customer.id, 0);
      }),
    )(null);
  }

  getCustomerWithFilters(offset) {
    const filterModels = this.filterCache.get(this.filterData.cachekey);
    const normalizedFilters = this.filterService.toFilter(filterModels);
    const sort = { [this.$state.params.sort]: this.$state.params.order };

    const customersServiceInstance = this.customersService();
    return customersServiceInstance
      .sort(sort)
      .filter(normalizedFilters)
      .slice(offset, undefined, 1)
      .getListWithQuery()
      .then((data) => {
        if (data?.length <= 0) {
          return { customer: null, total: null };
        }
        return data[0].plain();
      })
      .then((customer) => ({ customer, total: customersServiceInstance.total }))
      .catch((e) => {
        return { customer: null, total: null };
      });
  }

  goToCustomer(customerId, currentOffset) {
    this.$state.go('crm.contacts.view', { currentOffset, id: customerId });
  }
}

export default {
  template,
  controller: ContactPageController,
  require: { prfCustomerStoreProvider: '^' },
  controllerAs: 'vm',
  bindings: {},
};
