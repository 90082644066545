import ng from 'angular';
import { ChallengesListComponent } from '~/source/management/challenges/components/challenges-list/challenges-list.component';
import { ChallengesComponent } from '~/source/management/challenges/components/challenges/challenges.component';
import { ChallengeEditorComponent } from '~/source/management/challenges/components/challenge-editor/challenge-editor.component';
import { ChallengeLossProfitComponent } from '~/source/management/challenges/components/challenge-editor/challenge-loss-profit-component/challenge-loss-profit.component';
import { challengePhaseComponent } from '~/source/management/challenges/components/challenge-editor/challenge-phase-commponet/challenge-phase.component';
import { ChallengePeriodComponent } from '~/source/management/challenges/components/challenge-editor/challenge-period-componet/challenge-period.component';
import { ChallengeCurrencyBalanceComponent } from '~/source/management/challenges/components/challenge-editor/challenge-currency-balance/challenge-currency-balance.component';
import { ChallengeAssetsComponent } from '~/source/management/challenges/components/challenge-editor/challenge-assets-componet/challenge-assets.component';
import { ChallengeEarningComponent } from '~/source/management/challenges/components/challenge-editor/challenge-erning-component/challenge-erning.component';
import BrandChallengesGroupsService from '~/source/management/challenges/services/brand-challenges-groups.service';
import ChallengeGroupService from '~/source/management/challenges/services/challenge-group.service';
import { ChallengeActivationApprovalDialogComponent } from '~/source/management/challenges/components/challenge-activation-approval-dialog/challenge-activation-approval-dialog.component';
import { ChallengePreviewComponent } from '~/source/management/challenges/components/chalenge-preview/challenge-preview.component';
const ChallengesModule = ng.module('crm.challenges', ['ui.router']);

function config($stateProvider, $urlRouterProvider) {
  $urlRouterProvider.when(
    '/crm/management/challenges',
    '/crm/management/challenges/list',
  );

  $stateProvider
    .state('crm.management.challenges', {
      url: '/challenges',
      template: '<ui-view/>',
      redirectTo: 'crm.management.challenges.list',
    })
    .state('crm.management.challenges.list', {
      component: 'prfChallenges',
      url: '/list',
      data: {
        permissions: {
          only: ['management.challenges'],
          redirectTo: 'error.unauthorized',
        },
      },
    })
    .state('crm.management.challenges.create', {
      component: 'prfChallengeEditor',
      url: '/create?brandId&challengeType',
      data: {
        permissions: {
          only: ['management.challenges_C'],
          redirectTo: 'error.unauthorized',
        },
      },
    })
    .state('crm.management.challenges.update', {
      url: '/update/:challengeId?brandId',
      component: 'prfChallengeEditor',
      data: {
        permissions: {
          only: ['management.challenges_U'],
          redirectTo: 'error.unauthorized',
        },
      },
    })
    .state('crm.management.challenges.copy', {
      url: '/copy/:challengeId?brandId',
      component: 'prfChallengeEditor',
      data: {
        permissions: {
          only: ['management.challenges_C'],
          redirectTo: 'error.unauthorized',
        },
      },
    })
    .state('crm.management.challenges.preview', {
      url: '/preview/:challengeId?brandId',
      component: 'prfChallengePreview',
      data: {
        permissions: {
          only: ['management.challenges'],
          redirectTo: 'error.unauthorized',
        },
      },
    });
}

config.$inject = ['$stateProvider', '$urlRouterProvider'];

ChallengesModule.config(config)
  .component('prfChallenges', ChallengesComponent)
  .component('prfChallengesList', ChallengesListComponent)
  .component('prfChallengeEditor', ChallengeEditorComponent)
  .component('prfChallengeLossProfit', ChallengeLossProfitComponent)
  .component('prfChallengePhase', challengePhaseComponent)
  .component('prfChallengePeriod', ChallengePeriodComponent)
  .component('prfChallengeAssets', ChallengeAssetsComponent)
  .component('prfChallengeEarning', ChallengeEarningComponent)
  .component('prfChallengeCurrencyBalance', ChallengeCurrencyBalanceComponent)
  .component(
    'prfChallengeActivationApproval',
    ChallengeActivationApprovalDialogComponent,
  )
  .component('prfChallengePreview', ChallengePreviewComponent)
  .service('brandChallengesGroupsService', BrandChallengesGroupsService)
  .service('challengeGroupService', ChallengeGroupService);
export default ChallengesModule.name;
