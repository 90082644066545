import template from './challenge-preview.component.html';
import { StateParams, StateService } from '@uirouter/core';
import {
  Challenge,
  ChallengeAccountBalanceConfig,
  ChallengeGroup,
  ChallengePhase,
} from '@proftit/crm.api.models.entities';
import BrandChallengesGroupsService from '~/source/management/challenges/services/brand-challenges-groups.service';
import { CHALLENGE_TYPES } from '@proftit/crm.api.models.entities/src';
import { parseChallengesResponse } from '~/source/management/challenges/components/challenge-editor/challenges-editor-uils';

const styles = require('./challenge-preview.scss');

export class ChallengePreviewController {
  styles = styles;
  challenge: Challenge;
  challengeGroup: ChallengeGroup;
  accountBalances: ChallengeAccountBalanceConfig[][];
  phases: ChallengePhase[];
  challengeTypes = CHALLENGE_TYPES;

  /* @ngInject */
  constructor(
    readonly $state: StateService,
    readonly $stateParams: StateParams,
    readonly brandChallengesGroupsService: BrandChallengesGroupsService,
  ) {}

  async $onInit() {
    this.challengeGroup = await this.fetchChallenge(
      this.$stateParams.brandId,
      this.$stateParams.challengeId,
    );
    this.accountBalances = parseChallengesResponse(
      this.challengeGroup.challenges as Challenge[],
      'update',
    );
    this.phases = this.challengeGroup.challenges[0].phases as ChallengePhase[];
  }

  $onDestroy() {}

  $onChanges() {}

  async fetchChallenge(
    brandId: number,
    challengeGroupId: number,
  ): Promise<ChallengeGroup> {
    try {
      const data = await this.brandChallengesGroupsService.getChallengesGroupById(
        brandId,
        challengeGroupId,
      );
      return data.plain() as ChallengeGroup;
    } catch (error) {
      console.error(error);
    }
  }
}

export const ChallengePreviewComponent = {
  template,
  controller: ChallengePreviewController,
};
